import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Constrain from "../../../../../components/constrain"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Grid from "../../../../../components/grid"
import Heading from "../../../../../components/heading"
import Image from "../../../../../components/image"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import ReadMore from "../../../../../components/read-more"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import NoBreak from "../../../../../components/no-break"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query KlemmGaronneQuery {
      garonne: file(relativePath: { eq: "hoelderlins-orte/die-garonne.jpg" }) {
        ...largeImage
      }
      barbaraKlemm: file(
        relativePath: { eq: "hoelderlins-orte/barbara-klemm.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400, layout: CONSTRAINED)
        }
      }
    }
  `)

  const ModalTemplate = (props) => {
    const { title, image, children } = props
    return (
      <Stack>
        <Constrain>
          <Stack>
            <Heading level={3}>{title}</Heading>
            {image}
            {children}
          </Stack>
        </Constrain>
      </Stack>
    )
  }

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/reisen" />
      }
    >
      <Seo title="Ein Foto zum Gedicht " />
      <Stack>
        <Heading as="h2" level={2}>
          Ein Foto zum Gedicht
        </Heading>
        <Paragraph>
          Für die Wanderausstellung ›Hölderlins Orte‹ hat die Fotografin{" "}
          <ReadMore
            content={
              <ModalTemplate
                title="Barbara Klemm"
                image={
                  <Image
                    image={data.barbaraKlemm.childImageSharp.gatsbyImageData}
                    alt="Ein Schwarzweiß-Porträt zeigt Barbara Klemm"
                  />
                }
              >
                <Paragraph>
                  Als Fotografin für die Frankfurter Allgemeine Zeitung hat
                  Barbara Klemm das Bildgedächtnis der deutschen Bundesrepublik
                  mit geprägt.
                </Paragraph>
                <Paragraph>
                  1939 in Münster/Westfalen geboren, begann sie 1959 für die FAZ
                  zu arbeiten. Über 35 Jahre lang war sie als
                  Redaktionsfotografin unterwegs – insbesondere in Ost- und
                  Westeuropa und Südamerika. Längst haben sich ihre Fotografien
                  in das ikonografische Gedächtnis der deutschen Teilung und
                  Wiedervereinigung eingeschrieben, haben unser Bild von
                  Politiker*innen, Künstler*innen und Personen des öffentlichen
                  Lebens geprägt. Neben den großen, bedeutenden Gesichtern und
                  zeitgeschichtlichen Ereignissen hat sie mit ihrer Kamera aber
                  auch das scheinbar Unbedeutende – Alltagsmomente,
                  Landschaften, Museen und Straßenszenen – eingefangen.
                </Paragraph>
                <Paragraph>
                  Seit 1992 ist sie Mitglied der Akademie der Künste
                  Berlin-Brandenburg, seit 2000 hat sie eine Honorarprofessorin
                  an der FH Darmstadt. Ihre Fotografien wurden in zahlreichen
                  Museen in Deutschland sowie u.a. in Vietnam, Indien, Russland,
                  Lateinamerika, USA, Südkorea, Italien und Frankreich
                  ausgestellt. 2010 wurde sie in den Orden »Pour le mérite für
                  Wissenschaften und Künste« aufgenommen.
                </Paragraph>
              </ModalTemplate>
            }
          >
            <NoBreak>Barbara</NoBreak> Klemm
          </ReadMore>{" "}
          eine Reisefotografie aus ihrem Archiv herausgesucht, in der sich die
          Landschaftsbeschreibung und Perspektive der 1. Strophe des Gedichts
          wiederspiegelt.
        </Paragraph>
        <Grid columns={[1, 2]}>
          <PaperStack>
            <Poem size={[3, 3, 4]}>
              <p>Der Nordost wehet, </p>
              <p>Der liebste unter den Winden </p>
              <p>Mir, weil er feurigen Geist </p>
              <p>Und gute Fahrt verheißet den Schiffern. </p>
              <p>Geh aber nun und grüße </p>
              <p>Die schöne Garonne, </p>
              <p>Und die Gärten von Bourdeaux </p>
              <p>Dort, wo am scharfen Ufer</p>
              <p>Hingehet der Steg und in den Strom</p>
              <p>Tief fällt der Bach, darüber aber</p>
              <p>Hinschauet ein edel Paar</p>
              <p>Von Eichen und Silberpappeln</p>
            </Poem>
          </PaperStack>
          <Image
            image={data.garonne.childImageSharp.gatsbyImageData}
            caption="Die Garonne"
            alt="Schwarzweiß-Fotografie eines Stegs am Ufer der Garonne"
          />
        </Grid>
        <Paragraph>
          Die Fotografie zeigt das Ufer der Garonne in der Nähe von Bordeaux und
          lenkt den Blick zugleich Richtung Horizont, in die weite Ferne, wohin
          auch die Schiffe aufbrechen. Sogar der Steg am Ufer taucht auf dem
          Bild wieder auf.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
